import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Calculator = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedCounty, setSelectedCounty] = useState('San Diego');
    const [valuation, setValuation] = useState('');
    const [marketValue, setMarketValue] = useState('');
    const [calculated, setCalculated] = useState(false);
    const [annualDifference, setAnnualDifference] = useState(0);
    const [currentAnnualTax, setCurrentAnnualTax] = useState(0);
    const [fairShareAnnualTax, setFairShareAnnualTax] = useState(0);
    const averageSDTaxRate = 0.012;
    const fairShareTaxRate = 0.0046;

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const taxVal = params.get('taxval');
        const marketVal = params.get('marketval');
        if (taxVal && marketVal) {
            setValuation(formatNumber(taxVal));
            setMarketValue(formatNumber(marketVal));
            calculateTaxesBasedOnValuation(parseFloat(taxVal.replace(/,/g, '')), parseFloat(marketVal.replace(/,/g, '')));
        }
    }, [location.search]);

    const handleValuationChange = (event) => {
        const value = event.target.value.replace(/[^0-9]/g, '');
        setValuation(formatNumber(value));
    };

    const handleMarketValueChange = (event) => {
        const value = event.target.value.replace(/[^0-9]/g, '');
        setMarketValue(formatNumber(value));
    };

    const calculateTaxesBasedOnValuation = (taxValuation, marketValuation) => {
        const calculatedCurrentAnnualTax = taxValuation * averageSDTaxRate;
        const calculatedFairShareAnnualTax = marketValuation * fairShareTaxRate;
        const difference = calculatedCurrentAnnualTax - calculatedFairShareAnnualTax;

        setCurrentAnnualTax(calculatedCurrentAnnualTax);
        setFairShareAnnualTax(calculatedFairShareAnnualTax);
        setAnnualDifference(difference);
        setCalculated(true);
    };

    const calculateTaxes = (e) => {
        e.preventDefault();
        navigate(`?taxval=${valuation.replace(/,/g, '')}&marketval=${marketValue.replace(/,/g, '')}`, { replace: true });
        calculateTaxesBasedOnValuation(parseFloat(valuation.replace(/,/g, '')), parseFloat(marketValue.replace(/,/g, '')));
    };

    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const isInputValid = valuation.replace(/,/g, '') && marketValue.replace(/,/g, '');

    const Methodology = () => (
        <div className="mt-8 text-sm italic">
            <h2 className="text-xl font-bold mb-4">Methodology:</h2>
            <p className='mb-4'>
                The "current property tax valuation" is the assessed value for tax purposes. The "current property market value" is the estimated current sale price. To calculate current annual taxes we multiply the property tax valuation by the average San Diego County tax rate of {averageSDTaxRate * 100}%. To calculate "Fair Share" taxes, we multiply the current market value by the fair share tax rate of {(fairShareTaxRate * 100).toFixed(2)}%.
            </p>
            <p>
                Where do we get that? It's the rate that would produce the same total property tax receipts for the county if the average value of all parcels were equal to the 2022 average parcel value. That is, if valuations for property tax purposes were set at the market price, the tax <strong>rate</strong> could be <strong>cut by almost two thirds</strong> with no loss of revenue.
            </p>
        </div>
    );

    return (
        <div>
            <Helmet>
                <title>Fair Tax California | Calculate</title>
            </Helmet>
            <div className="flex justify-between items-center mb-6 mt-8">
                <h1 className="text-3xl font-bold">Property Tax Savings Calculator</h1>
                <div>
                    <select
                        id="county-select"
                        value={selectedCounty}
                        onChange={(e) => setSelectedCounty(e.target.value)}
                        className="shadow border rounded py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm"
                    >
                        <option value="San Diego">San Diego</option>
                        <option value="Los Angeles" disabled>Los Angeles</option>
                        <option value="San Francisco" disabled>San Francisco</option>
                    </select>
                    <p className="text-xs italic text-gray-600 mt-1">Select your county</p>
                </div>
            </div>
            <form onSubmit={calculateTaxes} className="flex flex-col gap-4">
                <div className="flex flex-col md:flex-row md:items-center gap-4">
                    <label htmlFor="current-valuation" className="md:w-1/3 block text-gray-700 text-lg font-bold mb-2 md:mb-0">
                        Current property tax valuation ($):
                    </label>
                    <div className="md:w-2/3">
                        <input
                            type="text"
                            id="current-valuation"
                            value={valuation}
                            onChange={handleValuationChange}
                            className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="0"
                        />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row md:items-center gap-4">
                    <label htmlFor="current-market-value" className="md:w-1/3 block text-gray-700 text-lg font-bold mb-2 md:mb-0">
                        Current property market value ($):
                    </label>
                    <div className="md:w-2/3">
                        <input
                            type="text"
                            id="current-market-value"
                            value={marketValue}
                            onChange={handleMarketValueChange}
                            className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="0"
                        />
                    </div>
                </div>

                <div className="flex justify-end">
                    <button
                        type="submit"
                        disabled={!isInputValid}
                        className={`mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${!isInputValid ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        Calculate
                    </button>
                </div>

            </form>

            {calculated && (
                <div className="mt-8 w-full">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                        <div className="card bg-white shadow-lg rounded p-4">
                            <h2 className="text-2xl font-bold">Annual Taxes Now</h2>
                            <p className="text-2xl">${formatNumber(Math.round(currentAnnualTax))}</p>
                            <p className="text-sm italic text-gray-600">Rate: {(averageSDTaxRate * 100).toFixed(2)}%</p>
                        </div>
                        <div className="card bg-white shadow-lg rounded p-4">
                            <h2 className="text-2xl font-bold">Annual "Fair Share" Taxes</h2>
                            <p className="text-2xl">${formatNumber(Math.round(fairShareAnnualTax))}</p>
                            <p className="text-sm italic text-gray-600">Rate: {(fairShareTaxRate * 100).toFixed(2)}%</p>
                        </div>
                    </div>
                    <div className={`${annualDifference >= 0 ? 'bg-green-700' : 'bg-red-700'} text-white p-8 grid grid-cols-1 md:grid-cols-3 gap-4 items-center mt-4`}>
                        <div className="md:col-span-2 text-xl md:text-2xl">
                            {annualDifference >= 0 ?
                                `If everyone paid their fair share, you'd pay $${formatNumber(Math.round(annualDifference))} less per year.` :
                                `If everyone paid their fair share, you'd pay $${formatNumber(Math.abs(Math.round(annualDifference)))} more per year.`}
                        </div>
                        <div className="text-left">
                            <span className="block text-4xl md:text-6xl font-bold">${formatNumber(Math.abs(Math.round(annualDifference)))}</span>
                        </div>
                    </div>

                </div>
            )}
            <Methodology />
        </div>
    );
};

export default Calculator;