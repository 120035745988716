import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaMapMarkedAlt, FaHome, FaRandom } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const Comparator = () => {
    let { parcelId } = useParams();
    const navigate = useNavigate();
    const [newcomerData, setNewcomerData] = useState(null);
    const [freeloaderData, setFreeloaderData] = useState(null);
    const [taxDifference, setTaxDifference] = useState(null);
    const [taxMultiple, setTaxMultiple] = useState(null);
    const averageSDTaxRate = 0.012;

    const calculateAndSetTaxes = (newcomerAsrTotal, freeloaderAsrTotal) => {
        const difference = newcomerAsrTotal - freeloaderAsrTotal;
        const multiple = newcomerAsrTotal / freeloaderAsrTotal;
        setTaxDifference(Math.round(difference * averageSDTaxRate).toLocaleString());
        setTaxMultiple(Math.round(multiple * 10) / 10);
    };

    const toTitleCase = (str) => {
        if (typeof str !== 'string') return str;
        return str.replace(/\w\S*/g, (txt) => (
            txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
        ));
    };

    const createFullAddress = (parcel) => {
        const addressParts = [
            parcel.situs_address,
            parcel.situs_pre_dir,
            parcel.situs_street,
            parcel.situs_post_dir,
            parcel.situs_suffix
        ].filter(part => part !== undefined && part !== null)
            .map(part => toTitleCase(String(part)))
            .join(' ');

        const community = parcel.situs_community ? toTitleCase(parcel.situs_community) : '';

        return `${addressParts}${community ? ', ' : ''}${community}`;
    };


    const createMapsUrl = (address) => {
        return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    };

    const createZillowUrl = (address) => {
        const formattedAddress = address.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '');
        return `https://www.zillow.com/homes/${formattedAddress}_rb/`;
    };

    const fetchData = useCallback(async (dataFile, objectId) => {
        try {
            const response = await fetch(`/data/${dataFile}`);
            const data = await response.json();
            const parcelData = data.find(item => item.objectid.toString() === objectId);
            return parcelData;
        } catch (error) {
            return null;
        }
    }, []);

    const fetchParcelDetails = useCallback(async () => {
        if (!parcelId) return;

        try {
            const newcomerData = await fetchData('sd_newcomer_parcels.json', parcelId);
            if (newcomerData) {
                setNewcomerData(newcomerData);
                const freeloaderData = await fetchData('sd_freeloader_parcels.json', newcomerData.freeloader_objectid.toString());
                if (freeloaderData) {
                    setFreeloaderData(freeloaderData);
                    calculateAndSetTaxes(newcomerData.asr_total, freeloaderData.asr_total);
                }
            }
        } catch (error) {
            console.error('Error in fetchParcelDetails:', error);
        }
    }, [parcelId, fetchData]);

    const fetchRandomNewcomer = useCallback(() => {
        fetch('/data/sd_newcomer_parcels.json')
            .then(response => response.json())
            .then(data => {
                const randomIndex = Math.floor(Math.random() * data.length);
                const randomNewcomerData = data[randomIndex];
                navigate(`/compare/${randomNewcomerData.objectid}`, { replace: true });
                setNewcomerData(randomNewcomerData);
                fetchData('sd_freeloader_parcels.json', randomNewcomerData.freeloader_objectid.toString())
                    .then(freeloader => {
                        setFreeloaderData(freeloader);
                        calculateAndSetTaxes(randomNewcomerData.asr_total, freeloader.asr_total);
                    });
            })
            .catch(error => console.error('Error fetching random newcomer data:', error));
    }, [navigate, fetchData]);

    useEffect(() => {
        if (parcelId) {
            fetchParcelDetails();
        } else {
            fetchRandomNewcomer();
        }
    }, [parcelId, fetchParcelDetails, fetchRandomNewcomer]);

    const RandomizerButton = () => (
        <button
            className="px-3 py-1 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition duration-300 flex items-center"
            onClick={fetchRandomNewcomer}
            title="Get Another Comparison"
        >
            <FaRandom className="mr-2" /> Get another comparison
        </button>
    );

    const ParcelCard = ({ data, title }) => (
        <div className="card bg-white shadow-lg rounded p-4 my-4">
            <h3 className="text-2xl font-bold mb-2">{title}</h3>
            <div className="flex items-center mb-2">
                <span className="text-md flex-grow text-2xl">{createFullAddress(data)}</span>
                <a href={createMapsUrl(createFullAddress(data))} target="_blank" rel="noopener noreferrer" className="mr-2">
                    <FaMapMarkedAlt className="text-lg" />
                </a>
                <a href={createZillowUrl(createFullAddress(data))} target="_blank" rel="noopener noreferrer">
                    <FaHome className="text-lg" />
                </a>
            </div>
            <div className="mb-4 text-lg">
                <p><span className="text-2xl">{data.total_lvg_area}</span> square feet</p>
                <p><span className="text-2xl">{data.bedrooms}</span> bedrooms</p>
                <p><span className="text-2xl">{data.baths}</span> bathrooms</p>
                {data.pool === 'Y' && <p>Pool</p>}
                {data.par_view_bool && <p>View</p>}
                <p><span className="text-2xl">{data.year_effective_clean}</span> valuation year</p>
                <p><span className="text-2xl">${data.asr_total.toLocaleString()}</span> assessed value</p>
            </div>
            <div className="pt-4 border-t-2 border-gray-200">
                <p className="text-xl">
                    Annual Taxes: <span className="text-2xl font-bold">${Math.round(data.asr_total * averageSDTaxRate).toLocaleString()}</span>
                </p>
            </div>
        </div>
    );

    return (
        <div>
            <Helmet>
                <title>Fair Tax California | Compare</title>
            </Helmet>
            <div className="flex justify-between items-center mb-4 mt-8">
                <h1 className="text-3xl font-bold">Property Tax Comparison</h1>
                <RandomizerButton />
            </div>
            <div className="grid grid-cols-2 gap-4">
                {newcomerData && <ParcelCard data={newcomerData} title="Newcomer" />}
                {freeloaderData && <ParcelCard data={freeloaderData} title="Oldtimer" />}
            </div>
            {taxDifference && (
                <div className="mt-8 w-full">
                    <div className="bg-red-700 text-white p-8 grid grid-cols-1 md:grid-cols-3 gap-4 items-center">
                        <div className="text-left">
                            <span className="block text-4xl md:text-8xl font-bold">{taxMultiple}x</span>
                        </div>
                        <div className="md:col-span-2 text-xl md:text-2xl">
                            The newcomer's annual tax burden is <span className="font-bold"> {taxMultiple} times greater</span> for a very similar property. They pay <span className="font-bold">${taxDifference}</span> more per year in taxes annually.
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Comparator;