import React, { useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const validateEmail = (email) => {
        return isEmail(email, { allow_utf8_local_part: false });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateEmail(email)) {
            setMessage('Please enter a valid email address.');
            return;
        }

        try {
            const response = await fetch('https://80gdxybr62.execute-api.us-west-1.amazonaws.com/postemail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setMessage('Thank you for subscribing!');
                setEmail('');
            } else {
                setMessage('Failed to subscribe. Please try again later.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred. Please try again later.');
        }
    };

    const tweetMessage="Want to see just how grotesquely unfair Prop 13 can be? Check out Fair Tax California. \n\n"
    const tweetUrl="https://www.fairtaxcalifornia.org"
    const encodedMessage = encodeURIComponent(tweetMessage);
    const encodedUrl= encodeURIComponent(tweetUrl);
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}&url=${encodedUrl}`;

    return (
        <div className="bg-gray-300 text-gray-800 px-4 py-2">
            <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center max-w-6xl">
                <div className="w-full sm:w-auto">
                    <p className="text-sm mb-2 italic">
                        Sign up for <strong>very occasional</strong> updates on Prop 13 reform: 
                    </p>
                    <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                        <input
                            type="email"
                            placeholder="Your email address"
                            className="p-2 rounded text-gray-800 flex-1"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button type="submit" className="bg-gray-400 hover:bg-gray-500 rounded px-4 py-2">
                            Subscribe
                        </button>
                    </form>
                    {message && <p className="text-sm mt-2">{message}</p>}
                </div>
                <div className="flex space-x-4 items-center mt-4 sm:mt-0">
                    <p className="hidden sm:block">Share on:</p>
                    <a href="https://www.facebook.com/sharer/sharer.php?u=www.fairtaxcalifornia.org" target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-500">
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a href={twitterShareUrl} target="_blank" rel="noopener noreferrer" className="text-gray-800 hover:text-gray-500">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;