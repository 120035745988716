import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import Calculator from './pages/Calculator';
import Comparator from './pages/Comparison';
import Gawker from './pages/Gawker';
import About from './pages/About';

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen">
        <div className="flex-grow container mx-auto px-4 py-8 max-w-6xl">
          <Navigation />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/calculate" element={<Calculator />} />
            <Route path="/compare" element={<Comparator />} />
            <Route path="/compare/:parcelId" element={<Comparator />} />
            <Route path="/gawk" element={<Gawker />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;