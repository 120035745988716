import React from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = () => {
    const activeStyle = {
        fontWeight: 'bold',
    };

    return (
        <header className="flex flex-col sm:flex-row justify-between items-center py-4 text-center sm:text-left">
            <h1 className="text-5xl font-bold mb-4 sm:mb-0">Fair Tax California</h1>
            <nav>
                <ul className="flex space-x-4 justify-center sm:justify-start">
                    <li>
                        <NavLink
                            to="/"
                            style={({ isActive }) => isActive ? activeStyle : {}}
                            className="text-blue-500 hover:text-blue-700 transition duration-300"
                        >
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/calculate"
                            style={({ isActive }) => isActive ? activeStyle : {}}
                            className="text-blue-500 hover:text-blue-700 transition duration-300"
                        >
                            Calculate 
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/compare"
                            style={({ isActive }) => isActive ? activeStyle : {}}
                            className="text-blue-500 hover:text-blue-700 transition duration-300"
                        >
                            Compare
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/gawk"
                            style={({ isActive }) => isActive ? activeStyle : {}}
                            className="text-blue-500 hover:text-blue-700 transition duration-300"
                        >
                            Gawk
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/about"
                            style={({ isActive }) => isActive ? activeStyle : {}}
                            className="text-blue-500 hover:text-blue-700 transition duration-300"
                        >
                            About 
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </header>
    );
};

export default Navigation;