import React from 'react';
import { Helmet } from 'react-helmet';

const freeloaders = [
    {
        id: 1,
        zillowId: 16839111,
        assessedValue: 663962,
        marketValue: 22500000,
        actualAnnualTax: 8082,
    },
    {
        id: 2,
        zillowId: 15088707,
        assessedValue: 306549,
        marketValue: 9800000,
        actualAnnualTax: 3721,
    },
];

const Gawk = () => {
    return (
        <div className="container mx-auto py-8">
            <Helmet>
                <title>Fair Tax California | Gawk</title>
            </Helmet>
            <h2 className="text-3xl font-bold mb-6">Champion Property Tax Freeloaders</h2>
            <div className="grid grid-cols-1 gap-4">
                {freeloaders.map((freeloader, index) => {
                    const zillowUrl = `https://www.zillow.com/homedetails/${freeloader.zillowId}_zpid/`;
                    const imageUrl = `/images/freeloaders/${freeloader.zillowId}.jpg`;
                    const taxRate = freeloader.actualAnnualTax / freeloader.assessedValue;
                    const fairMarketAnnualTax = Math.round(freeloader.marketValue * taxRate);
                    const prop13Savings = fairMarketAnnualTax - freeloader.actualAnnualTax;

                    return (
                        <div key={freeloader.id} className={`bg-gray-100 rounded-lg overflow-hidden flex flex-col md:flex-row items-stretch p-4 relative ${index === 0 ? 'border-4 border-yellow-400' : ''}`}>
                            {index === 0 && (
                                <div className="absolute top-0 left-0 px-2 py-1 bg-yellow-400 text-white font-bold rounded-tr-lg rounded-br-lg">#1 Freeloader</div>
                            )}
                            {index !== 0 && (
                            <div className="absolute top-0 left-0 ml-4 mt-4 bg-blue-600 text-white rounded-full w-8 h-8 flex items-center justify-center font-bold">{freeloader.id}</div>
                            )}
                            <a href={zillowUrl} target="_blank" rel="noopener noreferrer" className="flex-shrink-0 w-full md:w-1/3">
                                <img src={imageUrl} alt="Property" className="w-full h-auto" />
                            </a>
                            <div className="flex-grow grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-4">
                                <div>
                                    <h4 className="text-lg font-bold">Prop 13</h4>
                                    <p className="text-sm text-gray-600">Valuation</p>
                                    <p className="text-2xl md:text-3xl">${freeloader.assessedValue.toLocaleString()}</p>
                                    <p className="text-sm text-gray-600">Annual Taxes</p>
                                    <p className="text-2xl md:text-3xl">${freeloader.actualAnnualTax.toLocaleString()}</p>
                                </div>
                                <div>
                                    <h4 className="text-lg font-bold">Fair Market</h4>
                                    <p className="text-sm text-gray-600">Valuation</p>
                                    <p className="text-2xl md:text-3xl">${freeloader.marketValue.toLocaleString()}</p>
                                    <p className="text-sm text-gray-600">Annual Taxes</p>
                                    <p className="text-2xl md:text-3xl">${fairMarketAnnualTax.toLocaleString()}</p>
                                </div>
                                <div className="lg:col-span-1 flex flex-col">
                                    <h4 className="text-lg font-bold mb-4">Annual Prop 13 Subsidy</h4>
                                    <p className="text-4xl md:text-4xl lg:text-5xl xl:text-6xl text-red-700">${prop13Savings.toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Gawk;