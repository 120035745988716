import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
    return (
        <main>
            <section className="mt-8">
                <h2 className="text-3xl font-bold mb-6">Exposing the Grotesque Unfairness of Prop 13</h2>
                <div className="text-xl">
                    <p className="mb-5">
                        In 1978 California voters limited the rate at which assessed property values could grow.
                    </p>
                    <p className="mb-5">
                        Property <strong>assessed values</strong> have been diverging from their <strong>market values</strong> ever since.
                    </p>
                    <p className='mb-5'>
                        As a result newer property owners shoulder a <strong>disproportionate share</strong> of the tax burden.
                    </p>
                    <p className="mb-5">
                        Our purpose here is to <strong>shed some light</strong> on the distortions and unfairness of this system.
                    </p>
                </div>
            </section>
            <section className="mt-8">
                <h2 className="text-2xl font-bold mb-4">Tools</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <Link to="/calculate" className="bg-blue-100 p-4 rounded-lg block hover:bg-blue-200 transition duration-300">
                        <h3 className="font-bold text-xl">Calculator</h3>
                        <p className="mb-4">
                            Estimate how much you would save in a more equitable system.
                        </p>
                        <span className="text-blue-500 hover:text-blue-700">Go to Calculator →</span>
                    </Link>
                    <Link to="/compare" className="bg-blue-100 p-4 rounded-lg block hover:bg-blue-200 transition duration-300">
                        <h3 className="font-bold text-xl">Comparator</h3>
                        <p className="mb-4">
                            See examples of stark Prop 13 disparities.
                        </p>
                        <span className="text-blue-500 hover:text-blue-700">Go to Comparator →</span>
                    </Link>
                    <Link to="/gawk" className="bg-blue-100 p-4 rounded-lg block hover:bg-blue-200 transition duration-300">
                        <h3 className="font-bold text-xl">Gawk</h3>
                        <p className="mb-4">
                            Gawk at some of the worst property tax freeloaders in the state.
                        </p>
                        <span className="text-blue-500 hover:text-blue-700">Go to Gawk →</span>
                    </Link>
                </div>
            </section>
        </main>
    );
};

export default HomePage;
