import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

const About = () => {
    return (
        <main className="container mx-auto py-8">
            <Helmet>
                <title>Fair Tax California | About</title>
            </Helmet>
            <section className="max-w-3xl">
                <div className="text-2xl mb-6">
                    <p className="mb-5">
                        Fair Tax California is an attempt to shed some light on the grotesque unfairness of California's Proposition 13.
                    </p>
                    <p className="mb-5">
                        The site was built by <Link to="https://twitter.com/sjforman" className="text-blue-500 hover:text-blue-700 transition duration-300">@sjforman</Link>, motivated by ire at an exceptionally egregious <Link to="/gawk" className="text-blue-500 hover:text-blue-700 transition duration-300">example</Link> of a Prop 13 injustice in La Jolla.
                    </p>
                    <p className="mb-5">
                        Prop 13 has survived many reform attempts over the years and remains broadly popular. But if relative newcomers to California really understood how much it harms them, perhaps that popularity could be dented and reform could become conceivable.
                    </p>
                </div>
                <div className="text-2xl">
                    <p className="mb-5">
                        Appreciate this effort and/or want to keep up-to-date of this project and similar efforts? Follow me on Twitter for updates and insights:
                    </p>
                    <a href="https://twitter.com/sjforman" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 inline-flex items-center justify-center font-bold">
                        <FontAwesomeIcon icon={faTwitter} className="mr-2" />
                        @sjforman
                    </a>
                </div>
            </section>
        </main>
    );
};

export default About;